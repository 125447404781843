<!--suppress ALL -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Autofinanciamento</div>
      </v-col>
    </v-row>
    <v-form>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          CNPJ Construtora
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input
            type="text"
            id="cnpjConstrutora"
            name="cnpjConstrutora"
            size="26"
            v-mask="masks.cnpj"
            v-model.lazy="cnpjConstrutora"
          />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="2">
          Prestação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="3">
          Data de Vencimento
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="3">
          Data de Pagamento
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Valor da Prestação
        </v-col>
      </v-row>
      <v-row :key="item.numero" class="form-row" no-gutters v-for="item in prestacoes">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="2">
          {{ item.numero }}
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="3">
          <input
            :value="item.dataVencimento"
            @input="updatePrestacao(item.numero, 'dataVencimento', $event)"
            id="dtVencimento1"
            name="dtRefCompr"
            type="text"
            v-mask="'##/####'"
          />
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="3">
          <input
            :value="item.dataPagamento"
            @input="updatePrestacao(item.numero, 'dataPagamento', $event)"
            id="dtPagamento1"
            name="dtPagamento"
            type="text"
            v-mask="'##/####'"
          />
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          <input
            :value="item.valorPrestacao"
            @input="updatePrestacao(item.numero, 'valorPrestacao', $event)"
            id="valorPrestacao1"
            name="valorPrestacao"
            type="text"
            v-currency="currency"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { mapAvaliacaoRiscoFields } from "../../../store/modules/caixaqui-avaliacao-risco";
import { OPTIONS } from "../../../config";
import { mapMutations } from "vuex";

export default {
  name: "CaixaquiAvaliacaoRiscosAutofinanciamento",

  data: () => ({
    masks: OPTIONS.masks,
    currency: OPTIONS.currency,
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  }),
  computed: {
    ...mapAvaliacaoRiscoFields({
      cnpjConstrutora: "autoFinanciamento.cnpjConstrutora",
      prestacoes: "autoFinanciamento.prestacoes"
    })
  },
  methods: {
    ...mapMutations("caixaquiAvaliacaoRisco", ["updatePrestacaoField"]),
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    updatePrestacao(numero, key, event) {
      this.updatePrestacaoField({ numero: numero, key: key, value: event.target.value });
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped></style>
